/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { BagelPhase } from '@api/models/bagel-phase.entity';
import { Bagel } from '@api/models/bagel.entity';
import { BagelQuestionStatus, PhaseKind } from '@api/models/enums';
import { createSelector } from '@ngrx/store';

import { GamesState } from '../games.reducer';
import { selectGames } from '../games.selectors';
import { BagelsPhaseScores } from './bagels-phase.facade';

export class BagelsPhaseSelectors {
	public static scores = (bagelId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const bagel = getBagel(gamesState, bagelId);

			if (!bagel) return null;

			const { questions } = bagel;

			const questionsHitted = questions.filter(
				({ status }) => status === BagelQuestionStatus.Hit,
			);
			const questionsMissed = questions.filter(
				({ status }) => status === BagelQuestionStatus.Miss,
			);
			const remainingWords = questions.filter(
				({ status }) =>
					status === BagelQuestionStatus.None ||
					status === BagelQuestionStatus.Pass,
			);

			return {
				time: bagel.time,
				hits: questionsHitted.length,
				misses: questionsMissed.length,
				remaining: remainingWords.length,
			} as BagelsPhaseScores;
		});

	public static questions = (bagelId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const bagel = getBagel(gamesState, bagelId);
			if (!bagel) return null;

			return bagel.questions;
		});

	public static inGame = (bagelId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const bagel = getBagel(gamesState, bagelId);
			if (!bagel) return null;

			const { questions, inGameQuestion } = bagel;
			return questions.find(({ _id }) => _id === inGameQuestion);
		});

	public static isOver = (bagelId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const bagel = getBagel(gamesState, bagelId);
			if (!bagel) return null;

			return bagel.over;
		});

	public static playing = (bagelId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const bagel = getBagel(gamesState, bagelId);
			if (!bagel) return null;

			return bagel.playing;
		});

	public static time = (bagelId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const bagel = getBagel(gamesState, bagelId);
			if (!bagel) return null;

			return bagel.time;
		});

	public static hasTurn = (bagelId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const bagel = getBagel(gamesState, bagelId);
			if (!bagel) return null;

			return bagel.hasTurn;
		});

	public static bagels = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			const bagelPhase = getBagelPhase(gamesState);

			if (!bagelPhase) return null;

			return bagelPhase.bagels;
		},
	);

	public static bagelIds = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			const bagelPhase = getBagelPhase(gamesState);

			if (!bagelPhase) return null;

			return bagelPhase.bagels.map(({ _id }) => _id);
		},
	);

	public static playerIds = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			const bagelPhase = getBagelPhase(gamesState);

			if (!bagelPhase) return null;

			return bagelPhase.bagels.map(({ player }) => player as string);
		},
	);
}

const getBagelPhase = (gamesState: GamesState): BagelPhase | null => {
	if (!gamesState.current) return null;
	const { phases } = gamesState.current;

	return phases.find(({ kind }) => kind === PhaseKind.Bagels) as BagelPhase;
};

const getBagel = (gamesState: GamesState, bagelId: string): Bagel | null => {
	const bagelPhase = getBagelPhase(gamesState);

	if (!bagelPhase) return null;

	const { bagels } = bagelPhase;
	return bagels.find(({ _id }) => _id === bagelId);
};
