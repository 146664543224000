/* eslint-disable @typescript-eslint/member-ordering */
import { EMPTY, interval, Observable, Subscription } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { BagelQuestion } from '@api/models/bagel-question.entity';
import { Bagel } from '@api/models/bagel.entity';
import { Team } from '@api/models/enums';
import { Player } from '@api/models/player.entity';
import { GamesSelectors } from '@bussiness/store/features/games/games.selectors';
import {
	BagelPhaseBagelUpdatedEvent,
	BagelPhaseTimeChangedEvent,
} from '@core/models/games/events/bagel-phase.events';
import { BagelPhaseSockets } from '@core/services/sockets/bagels-phase.sockets';
import { Store } from '@ngrx/store';

import { DeviceFacade } from '../../device/device.facade';
import { PlayersFacade } from '../players/players.facade';
import { BagelPhaseActions } from './bagels-phase.actions';
import { BagelsPhaseSelectors } from './bagels-phase.selectors';

export type BagelsPhaseScores = {
	time: number;
	hits: number;
	misses: number;
	remaining: number;
};

const ONE_SECOND = 1000;

@Injectable()
export class BagelsPhaseFacade {
	// Selectors
	public players$: Observable<Player[]> = this.playersFacade.all$.pipe(
		withLatestFrom(this.store.select(BagelsPhaseSelectors.playerIds)),
		map(([players, playerIds]: [Player[], string[]]) =>
			players.filter(({ _id }: Player) => playerIds.includes(_id)),
		),
	);

	public turnOwner$: Observable<Player> = this.store
		.select(BagelsPhaseSelectors.bagels)
		.pipe(
			withLatestFrom(this.playersFacade.all$),
			// eslint-disable-next-line @ngrx/avoid-mapping-selectors
			map(([bagels, players]: [Bagel[], Player[]]) => {
				const bagelWithTurn = bagels.find(
					({ hasTurn }: Bagel) => hasTurn,
				);
				return players.find(
					({ _id }: Player) => _id === bagelWithTurn?.player,
				);
			}),
		);

	public scores$ = (bagelId: string): Observable<BagelsPhaseScores> =>
		this.store.select(BagelsPhaseSelectors.scores(bagelId));

	public isOver$ = (bagelId: string): Observable<boolean> =>
		this.store.select(BagelsPhaseSelectors.isOver(bagelId));

	public questions$ = (bagelId: string): Observable<BagelQuestion[]> =>
		this.store.select(BagelsPhaseSelectors.questions(bagelId));

	public inGame$ = (bagelId: string): Observable<BagelQuestion> =>
		this.store.select(BagelsPhaseSelectors.inGame(bagelId));

	public playing$ = (bagelId: string): Observable<boolean> =>
		this.store.select(BagelsPhaseSelectors.playing(bagelId));

	public hasTurn$ = (bagelId: string): Observable<boolean> =>
		this.store.select(BagelsPhaseSelectors.hasTurn(bagelId));

	public bagels$: Observable<Bagel[]> = this.store.select(
		BagelsPhaseSelectors.bagels,
	);

	public bagelIds$: Observable<string[]> = this.store.select(
		BagelsPhaseSelectors.bagelIds,
	);

	public team$ = (bagelId: string): Observable<Team> =>
		this.store.select(BagelsPhaseSelectors.bagels).pipe(
			withLatestFrom(this.playersFacade.all$),
			map(([bagels, players]) => {
				const bagel = bagels.find(({ _id }: Bagel) => _id === bagelId);
				const player = players.find(
					({ _id }: Player) => _id === bagel?.player,
				);
				return player?.team;
			}),
		);

	public get defaultBagelId(): string {
		return this._defaultBagelId;
	}

	//Events
	public get bagelUpdateSuccess$(): Observable<Bagel> {
		return this.bagelPhaseSocket.bagelUpdated$.pipe(
			map(({ bagel }: BagelPhaseBagelUpdatedEvent) => bagel),
		);
	}

	private get _intervalSubscriptionIsOpen(): boolean {
		return this._intervalSubscription && !this._intervalSubscription.closed;
	}

	private _interval$: Observable<number> = interval(ONE_SECOND);
	private _intervalSubscription: Subscription;
	private _defaultBagelId: string;

	constructor(
		private store: Store,
		private bagelPhaseSocket: BagelPhaseSockets,
		private deviceFacade: DeviceFacade,
		private playersFacade: PlayersFacade,
	) {
		this.bagelPhaseSocket.timeUpdated$.subscribe(
			({ bagelId, time }: BagelPhaseTimeChangedEvent) =>
				this.store.dispatch(
					BagelPhaseActions.changeTime({ id: bagelId, time }),
				),
		);

		this.bagelPhaseSocket.bagelUpdated$.subscribe(
			(event: BagelPhaseBagelUpdatedEvent) => {
				const {
					bagel: {
						_id,
						playing,
						time,
						over,
						inGameQuestion,
						hasTurn,
					},
				} = event;

				if (!playing) this.toggleTimer(_id, false);

				if (time === 0) this.deviceFacade.playAudio('time-over');

				this.store.dispatch(
					BagelPhaseActions.updateSuccess({
						id: _id,
						playing,
						time,
						over,
						inGameQuestion: inGameQuestion as string,
						hasTurn,
					}),
				);
			},
		);

		this.bagelIds$
			.pipe(filter((bagelIds: string[]) => !!bagelIds))
			.subscribe(
				([firstId]: string[]) => (this._defaultBagelId = firstId),
			);
	}

	public togglePlaying(id: string, playing: boolean): void {
		this.store.dispatch(BagelPhaseActions.togglePlaying({ id, playing }));
		this.toggleTimer(id, playing);
	}

	public fix(
		bagelId: string,
		bagelQuestion: BagelQuestion,
		changeTurn: boolean,
	): void {
		this.store.dispatch(
			BagelPhaseActions.fix({ bagelId, bagelQuestion, changeTurn }),
		);
	}

	private toggleTimer(bagelId: string, playing: boolean): void {
		if (playing && this._intervalSubscriptionIsOpen) return;

		if (!playing && this._intervalSubscriptionIsOpen) {
			this._intervalSubscription.unsubscribe();
		}

		if (!playing) return;

		this._intervalSubscription = this._interval$
			.pipe(
				withLatestFrom(
					this.store.select(BagelsPhaseSelectors.time(bagelId)),
					this.store.select(GamesSelectors.id),
				),
				tap(([, time, gameId]: [number, number, string]) => {
					const newTime = time - 1;
					this.bagelPhaseSocket.updateTime({
						gameId,
						bagelId,
						time: newTime,
					});
					this.store.dispatch(
						BagelPhaseActions.changeTime({
							id: bagelId,
							time: newTime,
						}),
					);

					if (newTime === 0) {
						this.store.dispatch(
							BagelPhaseActions.togglePlaying({
								id: bagelId,
								playing: false,
							}),
						);
						this._intervalSubscription.unsubscribe();
					}
				}),
			)
			.subscribe(() => EMPTY);
	}
}
