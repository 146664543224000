import { Injectable } from '@angular/core';
import { BagelQuestionStatus } from '@api/models/enums';
import {
	CHEER_HITS,
	LARGE_CHEER_HITS,
} from '@bussiness/resources/game/bagel-phase.constants';
import { DeviceFacade } from '@bussiness/store/features/device/device.facade';
import { BagelPhaseWordUpdatedEvent } from '@core/models/games/events/bagel-phase.events';
import { BagelPhaseSockets } from '@core/services/sockets/bagels-phase.sockets';
import { Store } from '@ngrx/store';

import { BagelQuestionActions } from './bagel-question.actions';

@Injectable()
export class BagelQuestionFacade {
	private _consecutiveHits = 0;

	constructor(
		private store: Store,
		private bagelPhaseSockets: BagelPhaseSockets,
		private deviceFacade: DeviceFacade,
	) {
		this.bagelPhaseSockets.bagelQuestionUpdated$.subscribe(
			({ bagelId, bagelQuestion }: BagelPhaseWordUpdatedEvent) => {
				const { _id, status } = bagelQuestion;
				this.store.dispatch(
					BagelQuestionActions.updateSuccess({
						bagelId,
						_id,
						status,
					}),
				);

				if (status === BagelQuestionStatus.Hit) {
					this.deviceFacade.playAudio('hit');
					this._consecutiveHits++;
				} else if (status === BagelQuestionStatus.Miss) {
					this.deviceFacade.playAudio('miss');

					if (this._consecutiveHits >= CHEER_HITS)
						this.deviceFacade.playAudio('oooh-cheer');
					else this.deviceFacade.playAudio('oooh');

					this._consecutiveHits = 0;
				} else if (status === BagelQuestionStatus.Pass) {
					if (
						this._consecutiveHits >= CHEER_HITS &&
						this._consecutiveHits < LARGE_CHEER_HITS
					)
						this.deviceFacade.playAudio('cheer');
					else if (this._consecutiveHits >= LARGE_CHEER_HITS)
						this.deviceFacade.playAudio('large-cheer');

					this._consecutiveHits = 0;
				}
			},
		);
	}

	public update(bagelId: string, status: BagelQuestionStatus): void {
		this.store.dispatch(BagelQuestionActions.update({ bagelId, status }));
	}
}
